import React from "react";
import './Home.css';

const Home = () => {
    return (
        <div className="Home">
            <p className="Main-title">Julian DEGUT</p>
            <div className="Separator"></div>
            <a href="https://www.linkedin.com/in/julian-degut/">
                <img src={require("../images/linkedin.png")} alt="LinkedIn" height={100}/>
            </a>
            <a href="https://github.com/jdegut">
                <img src={require("../images/github.png")} alt="GitHub" height={100}/>
            </a>
        </div>
    );
};

export default Home;